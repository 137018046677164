import React, { Component } from 'react';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Frame from 'react-frame-component';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import getBlock from './blocks';
import getIcons from './icons';
import { ReactComponent as Logo } from './logo.svg';
import Modal from './Modal';
var dragula = require('react-dragula');

const iconList = getIcons();
const blockListArr = [];

Object.entries(iconList).forEach(([type, icons]) => {
  Object.keys(icons).map(name => blockListArr.push(`${name},${type}`));
});

const themeList = [
  'indigo',
  'yellow',
  'red',
  'purple',
  'pink',
  'blue',
  'green',
];

const desktopIcon = (
  <svg
    stroke="currentColor"
    strokeWidth={2}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
  >
    <rect x={2} y={3} width={20} height={14} rx={2} ry={2} />
    <path d="M8 21h8m-4-4v4" />
  </svg>
);

const phoneIcon = (
  <svg
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x={5} y={2} width={14} height={20} rx={2} ry={2} />
    <path d="M12 18h.01" />
  </svg>
);

const tabletIcon = (
  <svg
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x={4} y={2} width={16} height={20} rx={2} ry={2} />
    <path d="M12 18h.01" />
  </svg>
);

const clipboardIcon = (
  <svg
    viewBox="0 0 25 24"
    stroke="currentColor"
    strokeWidth={2}
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M19.914 1h-18v19" />
    <path d="M6 5v18h18V5z" />
  </svg>
);

const viewList = [
  {
    icon: desktopIcon,
    name: 'desktop',
  },
  {
    icon: tabletIcon,
    name: 'tablet',
  },
  {
    icon: phoneIcon,
    name: 'phone',
  },
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      darkMode: false,
      copied: false,
      sidebar: true,
      codeView: false,
      designView: false,
      currentKeyCode: null,
      view: 'desktop',
      theme: 'indigo',
      blockType: 'Blog',
      blockName: 'BlogA',
      markup: '',
      modalOpen: false,
    };

    this.changeMode = this.changeMode.bind(this);
    this.changeTheme = this.changeTheme.bind(this);
    this.changeBlock = this.changeBlock.bind(this);
    this.handleContentDidMount = this.handleContentDidMount.bind(this);
    this.changeView = this.changeView.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.toggleDesignView = this.toggleDesignView.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.keyboardNavigation = this.keyboardNavigation.bind(this);
    this.markupRef = React.createRef();
    this.textareaRef = React.createRef();
    this.sidebarRef = React.createRef();
    this.openerRef = React.createRef();
    this.cancelButtonRef = React.createRef(null);
  }

  setModalOpen = value => {
    this.setState({
      modalOpen: value,
    });
  };

  componentDidMount() {
    document.addEventListener('keydown', this.keyboardNavigation);
  }

  hideSidebar() {
    const sidebar = this.sidebarRef.current;
    const opener = this.openerRef.current;

    document.addEventListener('click', e => {
      if (e.target === opener) {
        return;
      }

      if (!e.target === sidebar || !sidebar.contains(e.target)) {
        this.setState({ sidebar: false });
      }
    });
  }

  keyboardNavigation(e) {
    const { blockType, blockName } = this.state;
    const blockStringFormat = `${blockName},${blockType}`;
    const keyCode = e.which || e.keyCode;

    switch (keyCode) {
      case 40: // Down
        e.preventDefault();
        blockListArr.forEach((block, index) => {
          if (block === blockStringFormat) {
            const newActiveBlock =
              index + 1 <= blockListArr.length - 1
                ? blockListArr[index + 1].split(',')
                : blockListArr[0].split(',');
            console.log(newActiveBlock);
            const newBlockName = newActiveBlock[0];
            const newBlockType = newActiveBlock[1];
            const newBlockNode = document.querySelector(
              `.block-item[block-name="${newBlockName}"]`
            );
            if (newBlockNode) newBlockNode.focus();
            this.setState({
              blockType: newBlockType,
              blockName: newBlockName,
              codeView: false,
              currentKeyCode: 40,
            });
          }
        });
        break;
      case 37: // Left
        e.preventDefault();
        this.setState({ sidebar: false, currentKeyCode: 37 });
        break;
      case 39: // Right
        e.preventDefault();
        this.setState({ sidebar: true, currentKeyCode: 39 });
        break;
      case 38: // Up
        e.preventDefault();
        blockListArr.forEach((block, index) => {
          if (block === blockStringFormat) {
            const newActiveBlock =
              index - 1 >= 0
                ? blockListArr[index - 1].split(',')
                : blockListArr[blockListArr.length - 1].split(',');
            const newBlockName = newActiveBlock[0];
            const newBlockType = newActiveBlock[1];
            const newBlockNode = document.querySelector(
              `.block-item[block-name="${newBlockName}"]`
            );
            if (newBlockNode) newBlockNode.focus();

            this.setState({
              blockType: newBlockType,
              blockName: newBlockName,
              codeView: false,
              currentKeyCode: 38,
            });
          }
        });
        break;
      default:
        return;
    }

    setTimeout(() => {
      if (
        keyCode === 37 ||
        keyCode === 38 ||
        keyCode === 39 ||
        keyCode === 40
      ) {
        this.setState({ currentKeyCode: null });
      }
    }, 200);
  }

  changeMode() {
    this.setState({ darkMode: !this.state.darkMode });
  }

  handleContentDidMount() {
    const iframe = document.querySelector('iframe');
    iframe.contentWindow.document.addEventListener(
      'keydown',
      this.keyboardNavigation
    );
    iframe.contentWindow.document.addEventListener('click', () =>
      this.setState({ sidebar: false })
    );

    setTimeout(() => {
      this.setState({
        ready: true,
        markup: this.markupRef.current.innerHTML,
      });
    }, 400);
  }

  beautifyHTML(codeStr) {
    const process = str => {
      let div = document.createElement('div');
      div.innerHTML = str.trim();
      return format(div, 0).innerHTML.trim();
    };

    const format = (node, level) => {
      let indentBefore = new Array(level++ + 1).join('  '),
        indentAfter = new Array(level - 1).join('  '),
        textNode;

      for (let i = 0; i < node.children.length; i++) {
        textNode = document.createTextNode('\n' + indentBefore);
        node.insertBefore(textNode, node.children[i]);

        format(node.children[i], level);

        if (node.lastElementChild === node.children[i]) {
          textNode = document.createTextNode('\n' + indentAfter);
          node.appendChild(textNode);
        }
      }

      return node;
    };
    return process(codeStr);
  }

  changeBlock(e) {
    const { currentTarget } = e;
    const blockType = currentTarget.getAttribute('block-type');
    const blockName = currentTarget.getAttribute('block-name');
    this.setState({
      blockType,
      blockName,
      codeView: false,
    });
  }

  changeTheme(e) {
    const { currentTarget } = e;
    const theme = currentTarget.getAttribute('data-theme');
    this.setState({ theme });
  }

  changeView(e) {
    const { currentTarget } = e;
    const view = currentTarget.getAttribute('data-view');
    this.setState({ view, codeView: false });
  }

  toggleView() {
    this.setState({
      codeView: !this.state.codeView,
      view: 'desktop',
      markup: this.markupRef.current.innerHTML,
    });
  }

  toggleDesignView() {
    this.setState({ designView: !this.state.designView });
  }

  themeListRenderer() {
    const { theme } = this.state;
    return themeList.map((t, k) => (
      <button
        key={k}
        data-theme={t}
        onKeyDown={this.keyboardNavigation}
        className={`theme-button bg-${t}-500${theme === t ? ' is-active' : ''}`}
        onClick={this.changeTheme}
      ></button>
    ));
  }

  listRenderer() {
    const { blockName } = this.state;
    return Object.entries(iconList).map(([type, icons]) => (
      <div className="blocks" key={type}>
        <div className="block-category">{type}</div>
        <div className="block-list">
          {Object.entries(icons).map(icon => (
            <button
              key={icon[0]}
              tabIndex="0"
              onClick={e => {
                this.changeBlock(e);
              }}
              className={`block-item${
                icon[0] === blockName ? ' is-active' : ''
              }`}
              block-type={type}
              block-name={icon[0]}
            >
              {icon[1]}
            </button>
          ))}
        </div>
      </div>
    ));
  }

  viewModeRenderer() {
    const { view } = this.state;
    return viewList.map((v, k) => (
      <button
        key={k}
        className={`device${view === v.name ? ' is-active' : ''}`}
        data-view={v.name}
        onClick={this.changeView}
      >
        {v.icon}
      </button>
    ));
  }

  toggleSidebar() {
    this.setState({ sidebar: !this.state.sidebar });
  }

  copyToClipboard() {
    const code = this.beautifyHTML(this.state.markup);
    var input = document.createElement('textarea');
    input.innerHTML = code;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 2000);
  }

  render() {
    const {
      darkMode,
      theme,
      blockName,
      blockType,
      sidebar,
      view,
      copied,
      currentKeyCode,
    } = this.state;
    return (
      <div
        className={`app${darkMode ? ' dark-mode' : ''}${
          sidebar ? ' has-sidebar' : ''
        } ${theme} ${view}`}
      >
        <textarea className="copy-textarea" ref={this.textareaRef} />
        <aside className="sidebar" ref={this.sidebarRef}>
          {this.listRenderer()}
        </aside>
        <div className="toolbar">
          <button
            className="opener"
            onClick={this.toggleSidebar}
            ref={this.openerRef}
          >
            <Logo width="75px" />
          </button>
          <div>
            <Transition.Root show={this.state.modalOpen} as={Fragment}>
              <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={this.cancelButtonRef}
                onClose={() => this.setModalOpen(false)}
              >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                  </Transition.Child>
                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"></div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-lg leading-6 font-medium text-gray-900"
                            >
                              {blockName} Preview
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                {blockName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => this.setModalOpen(false)}
                        >
                          Deactivate
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => this.setModalOpen(false)}
                          ref={this.cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
          {this.state.codeView && (
            <div className="clipboard-wrapper">
              <button
                className="copy-the-block copy-to-clipboard"
                onClick={this.copyToClipboard}
              >
                {clipboardIcon}
                <span>COPY TO CLIPBOARD</span>
              </button>
              <span
                className={`clipboard-tooltip${copied ? ' is-copied ' : ''}`}
              >
                Copied!
              </span>
            </div>
          )}
          <button className="copy-the-block" onClick={this.toggleView}>
            {!this.state.codeView ? (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M16 18L22 12 16 6"></path>
                <path d="M8 6L2 12 8 18"></path>
              </svg>
            ) : (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="css-i6dzq1"
                viewBox="0 0 24 24"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            )}
            <span>{!this.state.codeView ? 'VIEW CODE' : 'PREVIEW'}</span>
          </button>
          <button className="copy-the-block" onClick={this.toggleDesignView}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M16 18L22 12 16 6"></path>
              <path d="M8 6L2 12 8 18"></path>
            </svg>
            <span>VIEW DESIGN</span>
          </button>
          <div className="switcher">{this.themeListRenderer()}</div>
          {this.viewModeRenderer()}
          <button className="mode" onClick={this.changeMode}></button>
        </div>
        <div className="markup" ref={this.markupRef}>
          {getBlock({ theme, darkMode })[blockType][blockName]}
        </div>
        <main
          className="main"
          style={{ opacity: this.state.ready ? '1' : '0' }}
        >
          <div className={`view${this.state.codeView ? ' show-code' : ''}`}>
            <Frame
              contentDidMount={this.handleContentDidMount}
              contentDidUpdate={this.handleContentDidUpdate}
              head={
                <>
                  <link
                    href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.0.2/tailwind.min.css"
                    rel="stylesheet"
                  />
                  {
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `img { filter:
                      ${
                        darkMode
                          ? 'invert(1) opacity(.5); mix-blend-mode: luminosity; }'
                          : 'sepia(1) hue-rotate(190deg) opacity(.46) grayscale(.7) }'
                      }`,
                      }}
                    />
                  }
                </>
              }
            >
              {!this.state.designView && (
                <div>{getBlock({ theme, darkMode })[blockType][blockName]}</div>
              )}
              {this.state.designView && <h1>Design View</h1>}
            </Frame>
            <div className="codes">
              <SyntaxHighlighter
                language="html"
                style={darkMode ? vs2015 : docco}
                showLineNumbers
              >
                {this.beautifyHTML(this.state.markup)}
              </SyntaxHighlighter>
            </div>
          </div>
        </main>
        <div className="keyboard-nav">
          <div
            className={`k-up keyboard-button${
              currentKeyCode === 38 ? ' is-active' : ''
            }`}
            data-info="Previous block"
          >
            <svg
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M12 19V5M5 12l7-7 7 7" />
            </svg>
          </div>
          <div className="keyboard-nav-row">
            <div
              className={`k-left keyboard-button${
                currentKeyCode === 37 ? ' is-active' : ''
              }`}
              data-info="Hide sidebar"
            >
              <svg
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
            </div>
            <div
              className={`k-down keyboard-button${
                currentKeyCode === 40 ? ' is-active' : ''
              }`}
              data-info="Next block"
            >
              <svg
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M12 5v14M19 12l-7 7-7-7" />
              </svg>
            </div>
            <div
              className={`k-right keyboard-button${
                currentKeyCode === 39 ? ' is-active' : ''
              }`}
              data-info="Show sidebar"
            >
              <svg
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
