import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery';
import LightBlogA from './blocks/blog/light/a';

import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Frame from 'react-frame-component';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import getBlock from './blocks';
import getIcons from './icons';
import { ReactComponent as Logo } from './logo.svg';
import Modal from './Modal';
import Dragula from 'react-dragula';
import DragulaStyles from 'react-dragula/dist/dragula.min.css';
import jquery from 'jquery';
import LightBlogB from './blocks/blog/light/b';

function Main() {
  const iconList = getIcons();
  const blockListArr = useMemo(() => [], []);
  const containers = useMemo(() => [], []);
  const dragulaDecorator = componentBackingInstance => {
    if (componentBackingInstance) {
      containers.push(componentBackingInstance);
    }
  };

  const markupRef = useRef();
  const textareaRef = useRef();
  const sidebarRef = useRef();
  const openerRef = useRef();
  const dragulaRef = useRef();
  const cancelButtonRef = React.createRef(null);

  Object.entries(iconList).forEach(([type, icons]) => {
    Object.keys(icons).map(name => blockListArr.push(`${name},${type}`));
  });

  const themeList = [
    'indigo',
    'yellow',
    'red',
    'purple',
    'pink',
    'blue',
    'green',
  ];

  const desktopIcon = (
    <svg
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
    >
      <rect x={2} y={3} width={20} height={14} rx={2} ry={2} />
      <path d="M8 21h8m-4-4v4" />
    </svg>
  );

  const phoneIcon = (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x={5} y={2} width={14} height={20} rx={2} ry={2} />
      <path d="M12 18h.01" />
    </svg>
  );

  const tabletIcon = (
    <svg
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x={4} y={2} width={16} height={20} rx={2} ry={2} />
      <path d="M12 18h.01" />
    </svg>
  );

  const clipboardIcon = (
    <svg
      viewBox="0 0 25 24"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19.914 1h-18v19" />
      <path d="M6 5v18h18V5z" />
    </svg>
  );

  const viewList = [
    {
      icon: desktopIcon,
      name: 'desktop',
    },
    {
      icon: tabletIcon,
      name: 'tablet',
    },
    {
      icon: phoneIcon,
      name: 'phone',
    },
  ];

  const [ready, setReady] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [copied, setCopied] = useState(false);
  const [sidebar, setSideBar] = useState(true);
  const [codeView, setCodeView] = useState(false);
  const [designView, setDesignView] = useState(true);
  const [currentKeyCode, setCurrentKeyCode] = useState(null);
  const [view, setView] = useState('desktop');
  const [filename, setFileName] = useState('homepage');
  const [theme, setTheme] = useState('indigo');
  const [blockType, setBlockType] = useState('Blog');
  const [blockName, setBlockName] = useState('BlogA');
  const [markup, setMarkup] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const keyboardNavigation = useCallback(
    e => {
      const blockStringFormat = `${blockName},${blockType}`;
      const keyCode = e.which || e.keyCode;

      switch (keyCode) {
        case 40: // Down
          e.preventDefault();
          blockListArr.forEach((block, index) => {
            if (block === blockStringFormat) {
              const newActiveBlock =
                index + 1 <= blockListArr.length - 1
                  ? blockListArr[index + 1].split(',')
                  : blockListArr[0].split(',');
              const newBlockName = newActiveBlock[0];
              const newBlockType = newActiveBlock[1];
              const newBlockNode = document.querySelector(
                `.block-item[block-name="${newBlockName}"]`
              );
              if (newBlockNode) newBlockNode.focus();

              setBlockType(newBlockType);
              setBlockName(newBlockName);
              setCodeView(false);
              setCurrentKeyCode(40);
            }
          });
          break;
        case 37: // Left
          e.preventDefault();
          setSideBar(false);
          setCurrentKeyCode(37);
          break;
        case 39: // Right
          e.preventDefault();
          setSideBar(true);
          setCurrentKeyCode(39);
          break;
        case 38: // Up
          e.preventDefault();
          blockListArr.forEach((block, index) => {
            if (block === blockStringFormat) {
              const newActiveBlock =
                index - 1 >= 0
                  ? blockListArr[index - 1].split(',')
                  : blockListArr[blockListArr.length - 1].split(',');
              const newBlockName = newActiveBlock[0];
              const newBlockType = newActiveBlock[1];
              const newBlockNode = document.querySelector(
                `.block-item[block-name="${newBlockName}"]`
              );
              if (newBlockNode) newBlockNode.focus();

              setBlockType(newBlockType);
              setBlockName(newBlockName);
              setCodeView(false);
              setCurrentKeyCode(38);
            }
          });
          break;
        default:
          return;
      }

      setTimeout(() => {
        if (
          keyCode === 37 ||
          keyCode === 38 ||
          keyCode === 39 ||
          keyCode === 40
        ) {
          setCurrentKeyCode(null);
        }
      }, 200);
    },
    [blockListArr, blockName, blockType]
  );

  const componentDidMount = () => {
    // document.addEventListener('keyup', keyboardNavigation);
  };

  const [value, setValue] = useState('');
  const handleChange = e => {
    const html = e.containers[e.containers.length - 1];
    setValue(html.innerHTML);
  };

  useEffect(() => {
    $('.toggle-navigation-dropdown').on('click', () => {
      $('.navigation-dropdown').toggleClass('active');
    });
    // document.addEventListener('keydown', keyboardNavigation);
    document.addEventListener('click', function (event) {
      // If the clicked element does not have and is not contained by an element with the .click-me class, ignore it
      let elem = event.target;
      if (event.target.closest('.tc-composer .tc-block-item .btn-delete')) {
        // elem.parentNode.parentNode.parentNode.remove();
        elem.closest('.tc-block-item').remove();
      }
    });

    const drake = Dragula(containers, {
      copy: function (el, source) {
        return source.classList.contains('block-list');
      },
      accepts: function (el, target) {
        return target.className === 'tc-composer';
      },
      copySortSource: false,
      revertOnSpill: true,
    })
      .on('drag', function (el) {})
      .on('drop', function (el, container, source) {
        el.querySelectorAll('.tc-block-icon').forEach(function (a) {
          a.remove();
        });
        // var temp = document.createElement('div');
        // ReactDOM.render(
        //   getBlock({ theme: 'indigo', darkMode: false })['Blog']['BlogA'],
        //   temp
        // );
        // el.parentNode.replaceChild(temp.firstElementChild, el);
        // console.log(el.getElementsByTagName('section')[0]);
        handleChange(drake);
      });

    $(document).click(function (e) {
      var container = $('.dropdown-container');
      //check if the clicked area is dropDown or not
      if (container.has(e.target).length === 0) {
        $('.navigation-dropdown').removeClass('active');
      }
    });

    return () => {
      document.removeEventListener('keydown', keyboardNavigation);
      // document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [keyboardNavigation, containers]);

  const hideSidebar = () => {
    const sidebar = sidebarRef.current;
    const opener = openerRef.current;

    document.addEventListener('click', e => {
      if (e.target === opener) {
        return;
      }

      if (!e.target === sidebar || !sidebar.contains(e.target)) {
        setSideBar(false);
      }
    });
  };

  const changeMode = () => {
    setDarkMode(!darkMode);
  };

  const handleContentDidMount = () => {
    const iframe = document.querySelector('iframe');
    iframe.contentWindow.document.addEventListener(
      'keydown',
      keyboardNavigation
    );
    iframe.contentWindow.document.addEventListener('click', () =>
      setSideBar(false)
    );

    setTimeout(() => {
      setReady(true);
      setMarkup(markupRef.current.innerHTML);
    }, 400);
  };

  const beautifyHTML = codeStr => {
    const process = str => {
      let div = document.createElement('div');
      div.innerHTML = str.trim();
      return format(div, 0).innerHTML.trim();
    };

    const format = (node, level) => {
      let indentBefore = new Array(level++ + 1).join('  '),
        indentAfter = new Array(level - 1).join('  '),
        textNode;

      for (let i = 0; i < node.children.length; i++) {
        textNode = document.createTextNode('\n' + indentBefore);
        node.insertBefore(textNode, node.children[i]);

        format(node.children[i], level);

        if (node.lastElementChild === node.children[i]) {
          textNode = document.createTextNode('\n' + indentAfter);
          node.appendChild(textNode);
        }
      }

      return node;
    };
    return process(codeStr);
  };

  const changeBlock = e => {
    const { currentTarget } = e;
    const blockType = currentTarget.getAttribute('block-type');
    const blockName = currentTarget.getAttribute('block-name');
    setBlockType(blockType);
    setBlockName(blockName);
    setCodeView(false);
  };

  const changeTheme = e => {
    const { currentTarget } = e;
    const theme = currentTarget.getAttribute('data-theme');
    setTheme(theme);
  };

  const changeView = e => {
    const { currentTarget } = e;
    const view = currentTarget.getAttribute('data-view');
    setView(view);
    setCodeView(false);
    if (view === 'tablet' || view === 'phone') {
      setDesignView(false);
    } else {
      setDesignView(true);
    }
  };

  const toggleView = () => {
    setCodeView(!codeView);
    setView('desktop');
    setMarkup(markupRef.current.innerHTML);
  };

  const toggleDesignView = () => {
    setDesignView(!designView);
  };

  const toggleCodeView = () => {
    setCodeView(!codeView);
  };

  const downloadCode = () => {
    var a = document.body.appendChild(document.createElement('a'));
    a.download = `${filename}.html`;
    var html = document.createElement('div'),
      formattedHtml = '';
    html.innerHTML =
      document.getElementsByClassName('tc-composer')[0].innerHTML;
    var blocks = html.getElementsByClassName('tc-block-item');
    for (var i = 0; i < blocks.length; i++) {
      formattedHtml +=
        blocks[i].getElementsByClassName('tc-block-html')[0].firstChild
          .outerHTML;
    }
    a.href =
      'data:text/html;charset=utf-8,' +
      beautifyHTML(encodeURIComponent(formattedHtml));
    a.click();
  };

  const themeListRenderer = () => {
    return themeList.map((t, k) => (
      <button
        key={k}
        data-theme={t}
        onKeyDown={keyboardNavigation}
        className={`theme-button bg-${t}-500${theme === t ? ' is-active' : ''}`}
        onClick={changeTheme}
      ></button>
    ));
  };

  const listRenderer = () => {
    return Object.entries(iconList).map(([type, icons]) => (
      <div className="blocks" key={type}>
        <div className="block-category">{type}</div>
        <div className="block-list tc-blocks" ref={dragulaDecorator}>
          {Object.entries(icons).map(icon => (
            <div className="tc-block-item" key={icon[0]} data-key={icon[0]}>
              <div className="tc-block-controls">
                <button className="btn-control btn-delete">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      stroke="#FFFFFF"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </div>
              <div className="tc-block-icon">
                <button
                  tabIndex="0"
                  className={`block-item${
                    icon[0] === blockName ? ' is-active' : ''
                  }`}
                  block-type={type}
                  block-name={icon[0]}
                >
                  {icon[1]}
                </button>
              </div>
              <div className="tc-block-html">
                {getBlock({ theme, darkMode })[type][icon[0]]}
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const viewModeRenderer = () => {
    return viewList.map((v, k) => (
      <button
        key={k}
        className={`device${view === v.name ? ' is-active' : ''}`}
        data-view={v.name}
        onClick={changeView}
      >
        {v.icon}
      </button>
    ));
  };

  const toggleSidebar = () => {
    setSideBar(!sidebar);
  };

  const copyToClipboard = () => {
    const code = beautifyHTML(markup);
    var input = document.createElement('textarea');
    input.innerHTML = code;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div
      className={`app${darkMode ? ' dark-mode' : ''}${
        sidebar ? ' has-sidebar' : ''
      } ${theme} ${view}`}
    >
      <textarea className="copy-textarea" ref={textareaRef} />
      <aside className="sidebar" ref={sidebarRef}>
        {listRenderer()}
      </aside>
      <div className="toolbar">
        <button className="opener" onClick={toggleSidebar} ref={openerRef}>
          <Logo width="75px" />
        </button>
        <div>
          <Transition.Root show={modalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={() => setModalOpen(false)}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"></div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            {blockName} Preview
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">{blockName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setModalOpen(false)}
                      >
                        Deactivate
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setModalOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
        <div className="dropdown-container">
          <button className="toggle-navigation-dropdown">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div className="navigation-dropdown">
            <div className="grid grid-cols-1">
              <div className="dropdown-title">Template Name</div>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                value={filename}
                onInput={e => setFileName(e.target.value)}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md tc-filename"
              />
            </div>
            <div className="grid grid-cols-1">
              <div className="dropdown-title">Code</div>
            </div>
            <div className="grid grid-cols-3">
              <button className="dropdown-item" onClick={toggleCodeView}>
                View
              </button>
              <button className="dropdown-item" onClick={copyToClipboard}>
                Copy
                <span
                  className={`clipboard-tooltip${copied ? ' is-copied ' : ''}`}
                >
                  Copied!
                </span>
              </button>
              <button className="dropdown-item" onClick={downloadCode}>
                Export
              </button>
            </div>
          </div>
        </div>
        {/* <button className="copy-the-block" onClick={toggleView}>
          {!codeView ? (
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path d="M16 18L22 12 16 6"></path>
              <path d="M8 6L2 12 8 18"></path>
            </svg>
          ) : (
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="css-i6dzq1"
              viewBox="0 0 24 24"
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
          )}
          <span>{!codeView ? 'VIEW CODE' : 'PREVIEW'}</span>
        </button> */}
        {/* <button className="copy-the-block" onClick={toggleDesignView}>
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
          >
            <path d="M16 18L22 12 16 6"></path>
            <path d="M8 6L2 12 8 18"></path>
          </svg>
          <span>VIEW DESIGN</span>
        </button> */}
        {/* <div className="switcher">{themeListRenderer()}</div> */}
        {viewModeRenderer()}
        <button className="mode" onClick={changeMode}></button>
      </div>
      <div className="markup" ref={markupRef}>
        {getBlock({ theme, darkMode })[blockType][blockName]}
      </div>
      <main className="main">
        <div className={`view${codeView ? ' show-code' : ''}`}>
          <Frame
            contentDidMount={handleContentDidMount}
            head={
              <>
                <link
                  href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.0.2/tailwind.min.css"
                  rel="stylesheet"
                />
                {
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `img { filter:
                      ${
                        darkMode
                          ? 'invert(1) opacity(.5); mix-blend-mode: luminosity; }'
                          : 'sepia(1) hue-rotate(190deg) opacity(.46) grayscale(.7) }'
                      }`,
                    }}
                  />
                }
              </>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: value }}></div>
          </Frame>
          <div className={`tc-composer-view ${designView ? 'active' : ''}`}>
            <div
              key="composer"
              className="tc-composer"
              ref={dragulaDecorator}
            ></div>
          </div>
          <div className="codes">
            <SyntaxHighlighter
              language="html"
              style={darkMode ? vs2015 : docco}
              showLineNumbers
            >
              {beautifyHTML(value)}
            </SyntaxHighlighter>
          </div>
        </div>
      </main>
      <div className="keyboard-nav">
        <div
          className={`k-up keyboard-button${
            currentKeyCode === 38 ? ' is-active' : ''
          }`}
          data-info="Previous block"
        >
          <svg
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M12 19V5M5 12l7-7 7 7" />
          </svg>
        </div>
        <div className="keyboard-nav-row">
          <div
            className={`k-left keyboard-button${
              currentKeyCode === 37 ? ' is-active' : ''
            }`}
            data-info="Hide sidebar"
          >
            <svg
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
          </div>
          <div
            className={`k-down keyboard-button${
              currentKeyCode === 40 ? ' is-active' : ''
            }`}
            data-info="Next block"
          >
            <svg
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M12 5v14M19 12l-7 7-7-7" />
            </svg>
          </div>
          <div
            className={`k-right keyboard-button${
              currentKeyCode === 39 ? ' is-active' : ''
            }`}
            data-info="Show sidebar"
          >
            <svg
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
