import React from "react";
import PropTypes from  "prop-types";

function DarkFeatureH(props) {
  return (
    <section className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-4 py-4 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-white mb-4">
            Raw Denim Heirloom Man Braid
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            Blue bottle crucifix vinyl post-ironic four dollar toast vegan
            taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi
            pug.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="font-medium title-font tracking-widest text-white mb-4 text-sm text-center sm:text-left">
              SHOOTING STARS
            </h2>
            <nav className="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2">
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                First Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Second Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Third Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fourth Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fifth Link
              </a>
            </nav>
          </div>
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="font-medium title-font tracking-widest text-white mb-4 text-sm text-center sm:text-left">
              THE 400 BLOWS
            </h2>
            <nav className="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2">
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                First Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Second Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Third Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fourth Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fifth Link
              </a>
            </nav>
          </div>
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="font-medium title-font tracking-widest text-white mb-4 text-sm text-center sm:text-left">
              THE CATALYZER
            </h2>
            <nav className="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2">
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                First Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Second Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Third Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fourth Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fifth Link
              </a>
            </nav>
          </div>
          <div className="p-4 lg:w-1/4 sm:w-1/2 w-full">
            <h2 className="font-medium title-font tracking-widest text-white mb-4 text-sm text-center sm:text-left">
              NEPTUNE
            </h2>
            <nav className="flex flex-col sm:items-start sm:text-left text-center items-center -mb-1 space-y-2">
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                First Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Second Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Third Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fourth Link
              </a>
              <a href>
                <span className={`bg-gray-800 text-${props.theme}-400 w-4 h-4 mr-2 rounded-full inline-flex items-center justify-center`}>
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </span>
                Fifth Link
              </a>
            </nav>
          </div>
        </div>
        <button className={`flex mx-auto mt-16 text-white bg-${props.theme}-500 border-0 py-2 px-8 focus:outline-none hover:bg-${props.theme}-600 rounded text-lg`}>
          Button
        </button>
      </div>
    </section>
  );
}

DarkFeatureH.defaultProps = {
  theme: 'indigo'
};

DarkFeatureH.propTypes = {
  theme: PropTypes.string.isRequired
};

export default DarkFeatureH;
